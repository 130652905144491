import React from 'react';

import classNames from 'classnames';

export default function TitleBlock(props) {
	let { title } = props;
	let { children } = title[0];

	let render = [];
	for (let child of children) {
		let { marks } = child;

		let className = classNames({ 'text-primary': marks.includes('strong') });

		render.push(
			<span key={child._key} className={className}>
				{child.text}
			</span>,
		);
	}

	return <>{render}</>;
}
