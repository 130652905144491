import React from 'react';

import Section from './section.jsx';

import useTranslate from '../hooks/use-translate.js';
import Title from './title.jsx';
import SubTitle from './sub-title.jsx';

export default function FirstMeeting() {
	let t = useTranslate('first-meeting');

	return (
		<Section>
			<span className="block max-w-xl">
				<Title>{t('title')}</Title>
			</span>
			<SubTitle>{t('subtitle')}</SubTitle>
			<div className="grid mx-auto mt-12 items-x-center items-y-center">
				<iframe
					title="Agenda Tom"
					className="mt-6 max-w-[850px] h-[791px] md:h-[670px] w-full meetings-iframe-container z-10"
					data-src="https://meetings.hubspot.com/tom-vermeulen/meet-with-tom?embed=true"
					src="https://meetings.hubspot.com/tom-vermeulen/meet-with-tom?embed=true"
				></iframe>
				<script
					type="text/javascript"
					src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
				></script>
			</div>
		</Section>
	);
}
