import React from 'react';

export default function Hero(props) {
	let { children } = props;

	return (
		<section className="grid pt-10 md:pt-14 lg:pt-16 xl:pt-20 items-x-center items-y-center">
			<div>{children}</div>
		</section>
	);
}
