import React from 'react';

export default function Section(props) {
	let { children, ...other } = props;

	return (
		<section className="pt-20 pb-12 xl:pt-28" {...other}>
			{children}
		</section>
	);
}
