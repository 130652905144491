import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { graphql, Link, navigate } from 'gatsby';

import Button from '../components/button.jsx';
import Layout from '../components/layout.jsx';
import Section from '../components/section.jsx';
import TitleBlock from '../components/title-block.jsx';
import FirstMeeting from '../components/first-meeting.jsx';

import usePageContext from '../hooks/use-page-context.js';
import Title from '../components/title.jsx';
import SubTitle from '../components/sub-title.jsx';
import Fade from '../components/fade.jsx';
import RichContent from '../components/rich-content.jsx';
import Hero from '../components/hero.jsx';

export default function Home(props) {
	let { data } = props;
	let { language } = usePageContext();
	let { sanityHorizontalsPage, allSanityHorizontal } = data;
	let { title, seo } = sanityHorizontalsPage;
	let { nodes: horizontals } = allSanityHorizontal;
	let [selectedHorizontal, setSelectedHorizontal] = useState();

	useEffect(() => {
		let searchParams = new URLSearchParams(document.location.search);
		let type = searchParams.get('type');
		let vertical = horizontals.find((v) => v.name.replace(/\s/g, '-').toLowerCase() === type?.toLowerCase());
		if (vertical) {
			setSelectedHorizontal(vertical);
		} else {
			setSelectedHorizontal(horizontals[0]);
			let name = horizontals[0].name.replace(/\s/g, '-').toLowerCase();
			navigate(`?type=${name}`, { replace: true });
		}
	}, [horizontals]);

	function handleHorizontalClick(event, horizontal) {
		event.preventDefault();
		setSelectedHorizontal(horizontal);
		let name = horizontal.name.replace(/\s/g, '-').toLowerCase();
		navigate(`?type=${name}`, { replace: true });
	}

	let renderHorizontals = [];
	for (let horizontal of horizontals) {
		let className = classNames(
			'block px-2 py-1 mt-6 border rounded-full border-primary text-primary focus:outline-none focus-visible:bg-primary focus-visible:text-secondary',
			{
				'bg-primary text-secondary': horizontal.name === selectedHorizontal?.name,
			},
		);

		let name = horizontal.name.replace(/\s/g, '-').toLowerCase();
		renderHorizontals.push(
			<Link
				key={horizontal.id}
				to={`?type=${name}`}
				onClick={(event) => handleHorizontalClick(event, horizontal)}
				className="flex flex-col items-center flex-shrink-0"
			>
				<div className="relative w-56 h-56">
					<Fade opacity={0.2} />
					<img src={horizontal.examples[0].illustration?.asset?.url + "?fit=max&h=450"} className="absolute w-full h-full" />
				</div>
				<span className={className}>{horizontal.name}</span>
			</Link>,
		);
	}

	let renderHorizontalExamples = [];
	for (let example of selectedHorizontal?.examples || []) {
		if (renderHorizontalExamples.length % 2 === 0) {
			renderHorizontalExamples.push(
				<div key={example._key} className="flex flex-col md:contents">
					<RichContent blocks={example._rawDescription} />
					<img src={example.image?.asset?.url} className="self-center object-cover w-full h-full mt-6 md:mt-0" />
				</div>,
			);
		} else {
			renderHorizontalExamples.push(
				<div key={example._key} className="flex flex-col-reverse md:contents">
					<img src={example.image?.asset?.url} className="self-center object-cover w-full h-full mt-6 md:mt-0" />
					<RichContent blocks={example._rawDescription} />
				</div>,
			);
		}
	}

	let renderCase;
	let horizontalCase = selectedHorizontal?.case;
	if (horizontalCase) {
		let { _rawLanguages: languages, image, slug } = horizontalCase;
		let { title, description } = languages[language];
		renderCase = (
			<section className="mt-12 xl:mt-20 grid items-y-center relative min-h-[24rem]">
				<div className="z-10 flex flex-col px-6 py-12 mx-auto text-center sm:px-12 max-w-7xl">
					<h2 className="text-2xl text-primary">{title}</h2>
					<p className="mt-6 text-lg">{description}</p>
					<span className="mt-6">
						<Button as="link" to={`/${language}/references/${slug?.current}`}>
							Read more
						</Button>
					</span>
				</div>
				<img src={image?.asset?.url} className="absolute inset-0 object-cover w-full h-full filter brightness-[0.45]" />
			</section>
		);
	}

	let renderMoreHorizontals = [];
	for (let horizontal of horizontals) {
		if (selectedHorizontal?.id === horizontal.id) continue;
		let name = horizontal.name.replace(/\s/g, '-').toLowerCase();

		renderMoreHorizontals.push(
			<a
				key={horizontal.id}
				href={`?type=${name}`}
				onClick={(event) => handleHorizontalClick(event, horizontal)}
				className="my-1 md:mx-12 focus:outline-none focus:underline text-primary"
			>
				{horizontal.name}
			</a>,
		);
	}

	return (
		<Layout seo={seo}>
			<Hero>
				<div className="grid gap-12 xl:grid-cols-[1fr,2fr]">
					<h1 className="text-4xl md:text-5xl 2xl:text-6xl self-y-center">
						<TitleBlock title={title} />
					</h1>

					<div className="flex items-center px-4 pb-2 overflow-x-scroll gap-x-12 gap-y-6 lg:self-x-center lg:max-w-4xl lg:flex-wrap lg:justify-center lg:overflow-x-auto">
						{renderHorizontals}
					</div>
				</div>
			</Hero>

			<Section>
				<Title>{selectedHorizontal?.name}</Title>
				<SubTitle>{selectedHorizontal?.title}</SubTitle>

				<div className="grid max-w-screen-xl gap-6 mx-auto mt-12 text-lg md:gap-10 xl:gap-16 md:grid-cols-2 items-x-center items-y-center">
					{renderHorizontalExamples}
				</div>
			</Section>

			{renderCase}

			<Section>
				<h2 className="uppercase text-tertiary">More horizontals</h2>
				<div className="flex flex-col items-center justify-center mt-12 -my-1 text-3xl md:flex-row md:-mx-12 md:flex-wrap">
					{renderMoreHorizontals}
				</div>
			</Section>

			<FirstMeeting />
		</Layout>
	);
}

export const query = graphql`
	query HorizontalsPage($language: String!) {
		sanityHorizontalsPage(lang: { eq: $language }) {
			title {
				children {
					_key
					text
					marks
				}
			}
			seo {
				description
				image {
					asset {
						url
					}
				}
			}
		}

		allSanityHorizontal(filter: { lang: { eq: $language } }) {
			nodes {
				id
				title
				name
				case {
					_rawLanguages
					slug {
						current
					}
					image {
						asset {
							url
						}
					}
				}
				examples {
					_key
					_rawDescription(resolveReferences: { maxDepth: 5 })
					illustration {
						asset {
							url
						}
					}
					image {
						asset {
							url
						}
					}
				}
			}
		}
	}
`;
