import classNames from 'classnames';
import React from 'react';

export default function Fade(props) {
	let { scale = Fade.scale.default, opacity = '1' } = props;

	let className = classNames('absolute rounded-full', {
		'w-full h-full': scale === Fade.scale.default,
		'w-[150%] h-[150%] right-[-25%] top-[-25%]': scale === Fade.scale.large,
	});

	return (
		<div
			className={className}
			style={{ background: `radial-gradient(rgba(255, 255, 255, ${opacity}), transparent 70%)` }}
		></div>
	);
}

Fade.scale = {
	default: 'default',
	large: 'large',
};
